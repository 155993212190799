// ComplaintManagement.js

import { Button, Card, CardContent, Container, Grid, Typography } from '@mui/material';

import React from 'react';

const ComplaintManagement = () => {
  return (
    <Container>
      <Typography variant="h4" gutterBottom style={{fontWeight:700}}>
        Complaint Management
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Card>
            <CardContent>
              <Typography variant="h6">Complaint 1</Typography>
              <Typography>Description of complaint 1</Typography>
              <Button variant="contained" color="primary" fullWidth>
                Resolve Complaint
              </Button>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Card>
            <CardContent>
              <Typography variant="h6">Complaint 2</Typography>
              <Typography>Description of complaint 2</Typography>
              <Button variant="contained" color="primary" fullWidth>
                Resolve Complaint
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ComplaintManagement;
