import { Box, Button, Card, CardContent, Container, Divider, Grid, Typography } from '@mui/material';
import React, { useRef, useState } from 'react';

// Sample data for tours
const tours = [
  { id: 1, name: 'Tour 1', description: 'A beautiful trip through the mountains.', price: '100$', duration: '3 days' },
  { id: 2, name: 'Tour 2', description: 'Explore the beaches and cities.', price: '150$', duration: '5 days' },
  { id: 3, name: 'Tour 3', description: 'An adventure in the jungle.', price: '200$', duration: '7 days' },
];

const TourManagement = () => {
  // State to store the selected tour details
  const [selectedTour, setSelectedTour] = useState(null);

  // Create a reference to the Tour Details section
  const tourDetailsRef = useRef(null);

  // Function to handle button click and display tour details
  const handleManageTour = (tour) => {
    setSelectedTour(tour);  // Set the selected tour to show its details

    // Scroll to the Tour Details section
    if (tourDetailsRef.current) {
      tourDetailsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom align="center" sx={{ fontWeight: 600, marginBottom: '20px' }}>
        Tour Management
      </Typography>

      <Grid container spacing={3}>
        {tours.map((tour) => (
          <Grid item xs={12} sm={4} key={tour.id}>
            <Card sx={{ display: 'flex', flexDirection: 'column', boxShadow: 3 }}>
              <CardContent>
                <Typography variant="h6" sx={{ fontWeight: 600 }}>
                  {tour.name}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ marginBottom: '10px' }}>
                  {tour.description}
                </Typography>
                <Divider sx={{ marginBottom: '10px' }} />
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  sx={{ textTransform: 'none', fontWeight: 'bold' }}
                  onClick={() => handleManageTour(tour)}  // Show details for the clicked tour
                >
                  Manage Tour
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Render selected tour details */}
      {selectedTour && (
        <Box ref={tourDetailsRef} sx={{ marginTop: '40px', padding: '20px', border: '1px solid #ccc', borderRadius: '8px' }}>
          <Typography variant="h5" gutterBottom>
            Tour Details
          </Typography>
          <Typography variant="h6" sx={{ fontWeight: 600 }}>Name:</Typography>
          <Typography variant="body1">{selectedTour.name}</Typography>
          
          <Typography variant="h6" sx={{ fontWeight: 600, marginTop: '10px' }}>Description:</Typography>
          <Typography variant="body1">{selectedTour.description}</Typography>
          
          <Typography variant="h6" sx={{ fontWeight: 600, marginTop: '10px' }}>Price:</Typography>
          <Typography variant="body1">{selectedTour.price}</Typography>
          
          <Typography variant="h6" sx={{ fontWeight: 600, marginTop: '10px' }}>Duration:</Typography>
          <Typography variant="body1">{selectedTour.duration}</Typography>
        </Box>
      )}
    </Container>
  );
};

export default TourManagement;
