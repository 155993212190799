import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import ComplaintManagement from './components/ComplaintManagement';
import GuideManagement from './components/GuideManagement';
import PaymentManagement from './components/PaymentManagement';
import Sidebar from './components/Sidebar';
import TourManagement from './components/TourManagement';
import UserManagement from './components/UserManagement';

const App = () => {
  return (
    <Router>
      <div className="container-fluid">
        <div className="row">
          <Sidebar />
          <div className="col-md-9 main-content">
            <Routes>
              <Route path="/complaintManagement" element={<ComplaintManagement />} />
              <Route path="/guideManagement" element={<GuideManagement />} />
              <Route path="/paymentManagement" element={<PaymentManagement />} />
              <Route path="/tourManagement" element={<TourManagement />} />
              <Route path="/userManagement" element={<UserManagement />} />
            </Routes>
          </div>
        </div>
      </div>
    </Router>
  );
};

export default App;
