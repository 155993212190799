import { Box, Button, Card, CardContent, Container, Divider, Grid, Typography } from '@mui/material';
import React, { useRef, useState } from 'react';

// Sample data for guides
const guides = [
  { id: 1, name: 'Guide 1', bio: 'Experienced in mountain tours, 5 years of guiding.', languages: 'English, Spanish', rating: '4.8/5' },
  { id: 2, name: 'Guide 2', bio: 'Expert in cultural city tours, 3 years of experience.', languages: 'English, French', rating: '4.9/5' },
  { id: 3, name: 'Guide 3', bio: 'Certified naturalist and adventure guide.', languages: 'English, German', rating: '4.7/5' },
];

const GuideManagement = () => {
  // State to store selected guide details
  const [selectedGuide, setSelectedGuide] = useState(null);

  // Reference to Guide Details section
  const guideDetailsRef = useRef(null);

  // Function to handle button click and show guide details
  const handleViewProfile = (guide) => {
    setSelectedGuide(guide);  // Set selected guide to show its details

    // Scroll to the Guide Details section
    if (guideDetailsRef.current) {
      guideDetailsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom align="center" sx={{ fontWeight: 600, marginBottom: '20px' }}>
        Guide Management
      </Typography>

      {/* List of Guides */}
      <Grid container spacing={3}>
        {guides.map((guide) => (
          <Grid item xs={12} sm={4} key={guide.id}>
            <Card sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              boxShadow: 3,
              height: 220, // Set a fixed height for all cards
              display: 'flex', 
              justifyContent: 'space-between' // Ensures content is spaced evenly
            }}>
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography variant="h6" sx={{ fontWeight: 600 }}>
                  {guide.name}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ marginBottom: '10px' }}>
                  {guide.bio}
                </Typography>
                <Divider sx={{ marginBottom: '10px' }} />
                <Typography variant="body2" color="text.secondary">
                  {guide.languages}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ marginBottom: '10px' }}>
                  Rating: {guide.rating}
                </Typography>
              </CardContent>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                sx={{ textTransform: 'none', fontWeight: 'bold' }}
                onClick={() => handleViewProfile(guide)}  // Show details for clicked guide
              >
                View Profile
              </Button>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Display Guide Details when a guide is selected */}
      {selectedGuide && (
        <Box ref={guideDetailsRef} sx={{ marginTop: '40px', padding: '20px', border: '1px solid #ccc', borderRadius: '8px' }}>
          <Typography variant="h5" gutterBottom>
            Guide Details
          </Typography>
          <Typography variant="h6" sx={{ fontWeight: 600 }}>Name:</Typography>
          <Typography variant="body1">{selectedGuide.name}</Typography>
          
          <Typography variant="h6" sx={{ fontWeight: 600, marginTop: '10px' }}>Biography:</Typography>
          <Typography variant="body1">{selectedGuide.bio}</Typography>
          
          <Typography variant="h6" sx={{ fontWeight: 600, marginTop: '10px' }}>Languages Spoken:</Typography>
          <Typography variant="body1">{selectedGuide.languages}</Typography>
          
          <Typography variant="h6" sx={{ fontWeight: 600, marginTop: '10px' }}>Rating:</Typography>
          <Typography variant="body1">{selectedGuide.rating}</Typography>
        </Box>
      )}
    </Container>
  );
};

export default GuideManagement;
